<template>
  <v-card-text>
    <v-card flat>
      <v-card-title>
        <v-text-field
          style="max-width: 400px;"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          dense
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          :to="{ path: 'notificacao/adicionar' }"
          align="center"
          color="xbColor"
          class="white--text"
        >
          <v-icon left> mdi-plus</v-icon>
          Adicionar
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="feed"
          :loading="loading"
          :items-per-page="10"
          :sort-desc="true"
          class="data-tables data-tables__row-click"
        >
          <template v-slot:item.created_at="{ item }">
            <div>
              {{ item.created_at | dateFormat("dd/MM/yyyy HH:mm") }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import { index as fetchFeeds } from "@/api/admin/feed.js";
export default {
  name: "FeedPendentes",

  data() {
    return {
      loading: true,
      feed: [],
      search: "",
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "mensagem",
          value: "mensagem",
        },

        {
          text: "usuário",
          value: "user_nome",
        },
        {
          text: "Data",
          value: "created_at",
        },
      ];
    },
  },

  methods: {
    getFeed() {
      this.loading = true;
      fetchFeeds("?&status=1")
        .then((response) => {
          this.feed = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getFeed();
  },
};
</script>

<style></style>
